import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { v4 } from 'uuid'
import Img from 'gatsby-image'

import './FeatureIcons.scss'

class FeatureIcons extends React.Component {
  drawFeatureIcons() {
    const features = [
      {
        icon: 'beautifully-designed',
        title: 'Beautifully designed ',
        text:
          'Bespoke, high quality templates made carefully for your business. Make a great first impression.',
      },
      {
        icon: 'robust-development',
        title: 'Robust development',
        text:
          'Our projects are built with the latest technologies for top speed & security. After project completion, website maintenance is key.',
      },
      {
        icon: 'seo',
        title: 'SEO',
        text:
          'Boost your search engine rankings and gain an advantage over your competition. Using the latest techniques, improve your SEO to gain an upper hand.',
      },
      {
        icon: 'mobile-apps',
        title: 'Mobile Apps',
        text:
          'Cross-platform mobile apps which work on IOS, Android & Windows Desktop. Turn your ideas into reality and watch your app grow!',
      },
      {
        icon: 'ecommerce',
        title: 'Ecommerce',
        text:
          'Sell your products online with an ecommerce website. We specialise in Woocommerce & Opencart installations.',
      },
      {
        icon: 'wordpress-websites',
        title: 'Wordpress Websites',
        text:
          'Manage your own website content with Wordpress. We have great knowledge on the best plugins for a wide range of needs.',
      },
      {
        icon: 'hosting-maintenance',
        title: 'Hosting & maintenance',
        text:
          'We take care of hosting & updating your website so it always has the latest security updates and optimised performance.',
      },
      {
        icon: 'responsive',
        title: 'Responsive',
        text:
          'All of our websites & apps are built responsively by default, meaning our templates will scale to fit any screen size beautifully.',
      },
    ]
    return features.map(feature => (
      <div
        className="column is-12-mobile is-half-tablet is-one-third-desktop is-one-quarter-widescreen"
        key={v4()}
      >
        <div className="featureicon">
          <div className={`featureicon__icon ${feature.icon}`} />
          <h4>{feature.title}</h4>
          <p>{feature.text}</p>
        </div>
      </div>
    ))
  }

  render() {
    return (
      <div className="feature-icons-wrapper">
        <div className="container has-text-centered">
          <div className="website-intro max-w-54rem">
            <h3 className="subtitle">Your local web &amp; mobile developer</h3>
            <p className="lead is-size-5 is-size-6-mobile">
              I’m a Web & Mobile App Developer based in 
              {' '}
              <strong>Wisbech</strong>
              , covering the entire 
              {' '}
              <strong>Cambridgeshire</strong>
              {' '}
&
              {' '}
              <strong>Norfolk</strong>
              {' '}
regions, as well as international
              businesses.
            </p>
            <p className="lead is-size-5 is-size-6-mobile">
              I am able to offer a professional and personal service in lots of
              different areas, from web & mobile app development to SEO &
              graphic design, to help give your business the extra boost you
              need.
            </p>
            <p className="lead is-size-5 is-size-6-mobile">
              I can provide the same quality of website that you would find with
              large design agencies, but at a much more affordable price, so why
              not 
              {' '}
              <Link to="/contact">get in touch</Link>
              {' '}
to discuss your
              project?
            </p>
          </div>
          <div className="columns feature-icons is-mobile is-multiline">
            {this.drawFeatureIcons()}
          </div>
        </div>
      </div>
    )
  }
}

export default FeatureIcons
