import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { v4 } from 'uuid'
import SEO from '../components/SEO'
import FeatureIcons from '../components/FeatureIcons/FeatureIcons'
import '../styles/pages/projects.scss'
import Layout from '../components/Layout'

const seo = {
  title: 'Projects',
  description:
    'A showcase of our most recent and interesting web & mobile app development projects.',
}

const ProjectsGrid = ({ projects }) => {
  let index = 0

  const content = projects.map(p => {
    index++

    if (index % 3 === 0) {
      return (
        <div className="column is-12 fullwidth" key={v4()}>
          <Link to={`/project/${p.node.slug}`}>
            <Img
              className="is-hidden-mobile"
              fluid={
                p.node.acf.project_grid_image_full.localFile.childImageSharp
                  .fluid
              }
              alt={`${p.node.title} - project image fullwidth`}
            />
            <Img
              className="is-hidden-tablet"
              fluid={
                p.node.acf.project_grid_image_half.localFile.childImageSharp
                  .fluid
              }
              alt={`${p.node.title} - project image`}
            />
            <div className="text-wrapper">
              <h2 className="subtitle is-size-3 is-size-4-mobile no-underline-mobile">
                {p.node.title}
              </h2>
              <div
                className="project-brief is-hidden-mobile"
                dangerouslySetInnerHTML={{
                  __html: p.node.acf.the_project_brief_short,
                }}
              />
              <p className="project-tags">{p.node.acf.tags}</p>
            </div>
          </Link>
        </div>
      )
    }
    return (
      <div
        className={`column is-5 halfwidth ${
          index % 3 === 1 ? 'is-offset-1' : 'right-half'
        }`}
        key={v4()}
      >
        <Link to={`/project/${p.node.slug}`}>
          <Img
            fluid={
              p.node.acf.project_grid_image_half.localFile.childImageSharp.fluid
            }
            alt={`${p.node.title} - project image`}
          />
          <div className="text-wrapper">
            <h2 className="subtitle is-size-4 no-underline">{p.node.title}</h2>
            <p className="project-tags">{p.node.acf.tags}</p>
          </div>
        </Link>
      </div>
    )
  })
  return <div className="projects-grid columns is-multiline">{content}</div>
}

class ProjectsPage extends React.Component {
  render() {
    return (
      <Layout color="dark">
        <SEO title={seo.title} description={seo.description} />
        <div className="page-wrapper projects">
          <div>
            <div className="page-intro has-text-centered">
              <h1 className="subtitle is-size-2 is-size-3-mobile">
                Our Projects
              </h1>
              <p className="is-size-4 is-size-5-mobile">
                A showcase of our best & most recent projects
              </p>
            </div>
            <ProjectsGrid
              projects={this.props.data.allWordpressWpProjects.edges}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allWordpressWpProjects {
      edges {
        node {
          id
          title
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          acf {
            the_project_brief_short
            tags
            project_grid_image_half {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 430, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            project_grid_image_full {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ProjectsPage
